import React from "react"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { transformData } from "helpers/dataTransformer"
import { getNotificationService } from "services/agency.service"
import { getMgepsTokenService } from "services/authentication.service"

export const getMgepsTokenThunk = createAsyncThunk(
  "getMgepsToken",
  async (params, { rejectWithValue }) => {
    return await getMgepsTokenService(params, rejectWithValue)
  }
)

const initialLog = {
  type: "",
  error: false,
  status: null,
  message: null,
  loading: false,
  success: false,
}

const initialState = {
  token: null,
  userData: null,
  logs: initialLog,
}

const slice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    resetAuthState: (state, action) => {
      state.logs = initialLog
      state.userData = null
      state.token = null
    },
    saveToken: (state, action) => {
      state.token = action.payload
    },
  },
  extraReducers: {
    [getMgepsTokenThunk.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "getMgepsToken",
      }
    },
    [getMgepsTokenThunk.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }

      state.userData = {
        Data: action?.payload?.result?.Data, // userData
        role_type: action?.payload?.result?.role_type, // role
        SupPlatinumRegistrations:
          action?.payload?.result?.SupPlatinumRegistrations,
        SupplierRegDetails: action?.payload?.result?.SupplierRegDetails,
      }
    },
    [getMgepsTokenThunk.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        status: action?.payload?.errors[0]?.code,
        message: action?.payload?.errors ? (
          <p>{`${action?.payload?.errors[0]?.code}. ${action?.payload?.errors[0]?.detail}`}</p>
        ) : (
          "Something went wrong"
        ),
      }
    },
    ["PURGE"]: () => {
      return initialState
    },
  },
})

export const { reducer } = slice

export const saveTokenThunk = payload => dispatch => {
  dispatch(slice.actions.saveToken(payload))
}

export const resetAuthStateThunk = payload => dispatch => {
  dispatch(slice.actions.resetAuthState())
}

export default slice
